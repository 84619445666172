'use strict'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import './icons'

import './assets/main.scss'

import $ from 'jquery'

$('.alert').alert()
